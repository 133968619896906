import React, { useEffect } from "react";
import { FloatButton, Progress, Badge, Tooltip } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useUpload } from "../context/UploadContext";
import {
  FileTextOutlined,
  CheckCircleOutlined,
  FullscreenOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { baseUrl } from "../utils";
import { message } from "antd";

const UploadProgressFloat = () => {
  const { uploadState, updateUploadState } = useUpload();
  const navigate = useNavigate();
  const location = useLocation();

  // Don't show float button on upload page
  const shouldHideButton = location.pathname === "/employees/add";

  const getAuthHeaders = () => ({
    Authorization: `Bearer ${localStorage.getItem("hr-auth-token")}`,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  });

  // Move polling logic here
  useEffect(() => {
    let intervalId;

    const checkStatus = async () => {
      if (!uploadState.jobId) return;

      try {
        const response = await axios.get(
          `${baseUrl}/member/uploads/${uploadState.jobId}/status`,
          { headers: getAuthHeaders() }
        );

        if (response.data.success) {
          const result = response.data.result;
          updateUploadState({ status: result });

          if (result.status === "Completed") {
            const validatedResponse = await axios.get(
              `${baseUrl}/member/${uploadState.jobId}/validated-list`,
              { headers: getAuthHeaders() }
            );

            if (validatedResponse.data.success) {
              updateUploadState({
                status: {
                  ...result,
                  fileResponse: validatedResponse.data.result,
                },
              });
              message.success("File validation completed successfully");
              clearInterval(intervalId);
            }
          }
        }
      } catch (error) {
        console.error("Error checking status:", error);
        clearInterval(intervalId);
      }
    };

    if (uploadState.jobId && uploadState.isUploading) {
      intervalId = setInterval(checkStatus, 3000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [uploadState.jobId, uploadState.isUploading]);

  const handlePreview = () => {
    if (uploadState.status?.fileResponse) {
      const fileResponse = uploadState.status.fileResponse;

      // First clear all the state
      updateUploadState({
        jobId: null,
        status: null,
        isUploading: false,
      });

      // Then navigate with the data
      navigate("/bulkuploaded-preview", {
        state: { fileResponse },
      });
    }
  };

  const handleCancel = () => {
    updateUploadState({
      jobId: null,
      status: null,
      isUploading: false,
    });
    message.info("Upload cancelled");
  };

  const handleFullscreen = () => {
    navigate("/employees/add");
  };

  if (!uploadState.isUploading || shouldHideButton) return null;

  const isCompleted = uploadState.status?.fileResponse;
  const progress = uploadState.status?.processing_percentage || 0;

  return (
    <FloatButton.Group
      style={{
        insetInlineEnd: 24,
        insetBlockStart: 24,
      }}
    >
      {/* Cancel Button */}
      <Tooltip title="Cancel Upload" placement="left">
        <FloatButton
          icon={<CloseOutlined />}
          onClick={handleCancel}
          style={{
            color: "white",
          }}
        />
      </Tooltip>

      {/* Fullscreen Button */}
      <Tooltip title="View Full Page" placement="left">
        <FloatButton
          icon={<FullscreenOutlined />}
          onClick={handleFullscreen}
          style={{
            color: "white",
          }}
        />
      </Tooltip>

      {/* Existing Status Button */}
      {isCompleted ? (
        <Tooltip title="Validation Completed" placement="left">
          <FloatButton
            icon={<CheckCircleOutlined />}
            onClick={handlePreview}
            type="primary"
            badge={{
              count: uploadState?.status?.validated_records,
              color: "#008B82",
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          placement="left"
          title={
            <div style={{ padding: "8px" }}>
              <Progress
                percent={progress}
                size="small"
                status="active"
                strokeColor={{
                  from: "#008B82",
                  to: "#009FA2",
                }}
              />
              <div style={{ fontSize: "12px", marginTop: "4px" }}>
                {uploadState.status?.validated_records || 0} of{" "}
                {uploadState.status?.total_records || 0} records
              </div>
            </div>
          }
        >
          <FloatButton
            icon={<FileTextOutlined />}
            badge={{
              count: progress + "%",
              color: "#008B82",
            }}
          />
        </Tooltip>
      )}
    </FloatButton.Group>
  );
};

export default UploadProgressFloat;
