import React, { useEffect, useState } from "react";
import { ConfigProvider, notification, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import RouteSetup from "./router";
import { baseUrl } from "./utils";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { UploadProvider } from "./context/UploadContext";
import UploadProgressFloat from "./components/UploadProgressFloat";

const App = () => {
  const [deviceToken, setDeviceToken] = useState();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const hrUser = JSON.parse(localStorage.getItem("hrUser")) || {};

  // Example mutation for sending device token
  const { mutate: sendTokenToServer } = useMutation({
    mutationFn: async (deviceToken) => {
      const authToken = localStorage.getItem("x-auth-token");
      const response = await fetch(`${baseUrl}/user/device-token`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ device_token: deviceToken }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response.json();
    },
    onError: (error) => {
      console.error("Error sending device token:", error);
    },
  });

  useEffect(() => {
    if (
      !hrUser.hasOwnProperty("employer_subscriptions") &&
      !(
        window.location.pathname === "/login" ||
        window.location.pathname === "/welcome"
      )
    ) {
      setIsModalVisible(true);
    }
  }, []);

  // Example of using the mutation
  useEffect(() => {
    const authToken = localStorage.getItem("x-auth-token");
    if (authToken && deviceToken) {
      sendTokenToServer(deviceToken);
    }
  }, [deviceToken]);

  const handleLogout = () => {
    setIsModalVisible(false);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <UploadProvider>
      <Modal
        title="Major Update 🎉"
        open={isModalVisible}
        onOk={handleLogout}
        okText="Log Out"
        onCancel={null}
        closable={false}
        maskClosable={false}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <div style={{ marginTop: 20 }}>
          <p>
            We've updated the app to improve your experience. Please log out and
            log back in to access the latest features.
          </p>
        </div>
      </Modal>

      <RouteSetup />
      <UploadProgressFloat />
    </UploadProvider>
  );
};

export default App;
