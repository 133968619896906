import React, { createContext, useContext, useState } from "react";

const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
  const [uploadState, setUploadState] = useState({
    isUploading: false,
    jobId: null,
    status: null,
  });

  const updateUploadState = (newState) => {
    setUploadState((prev) => ({ ...prev, ...newState }));
  };

  return (
    <UploadContext.Provider value={{ uploadState, updateUploadState }}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUpload = () => useContext(UploadContext);
